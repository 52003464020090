<template>
  <div>
    <div class="header text-center">
      <h3 class="title">Notas importadas</h3>
    </div>
    <div class="md-layout">
      <div class="md-layout-item">
        <md-card>
          <md-card-content>
            <div class="md-tab-filter filter-container">
              <div class="field-filter">
                <span>Data inicial: </span>
                <md-datepicker v-model="initialDate" md-immediately />
              </div>
              <div class="field-filter">
                <span>Data final: </span>
                <md-datepicker v-model="finalDate" md-immediately />
              </div>
              <span style="margin-left: 2%"></span>
              <md-button v-if="!paginationIsLoading" class="md-raised md-warning md-lg" @click="filterNotes">
                <span>Filtrar</span>
              </md-button>
              <md-button v-else class="md-raised md-warning md-lg" disabled>
                <span>Filtrando...</span>
              </md-button>
            </div>
          </md-card-content>
        </md-card>
        <md-card>
          <md-card-content>
            <md-table v-if="isNotesLoaded" v-model="notes" md-card md-sort="note_date" md-sort-order="desc">
              <md-table-empty-state
                md-label="Nenhuma nota de corretagem encontrada..."
                :md-description="`Nenhuma nota de corretagem encontrada com os filtros acima.`">
              </md-table-empty-state>
              <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="Mercado | nº nota" v-if="item.market_id == 1" data-title="Mercado | nº nota">Bovespa | {{ item.note_number }}</md-table-cell>
                <md-table-cell md-label="Mercado | nº nota" v-if="item.market_id == 2" data-title="Mercado | nº nota">BM&F | {{ item.note_number }}</md-table-cell>
                <md-table-cell md-label="Mercado | nº nota" v-if="item.market_id == 3" data-title="Mercado | nº nota">Balcão | {{ item.note_number }}</md-table-cell>
                <md-table-cell md-label="Data da nota" data-title="Data da nota">{{ item.note_date | DateFormat }}</md-table-cell>
                <md-table-cell md-label="Corretora" data-title="Corretora">{{ item.stockbroker_object.name }}</md-table-cell>
                <md-table-cell md-label="Importada em" data-title="Importada em">{{ item.brockerage_note_object.created_at | DateTimeFormat }}</md-table-cell>
                <md-table-cell md-label="Nota" data-title="Nota">
                  <md-button @click.prevent="openBrokerageNote(item)" style="width: 200px"><span>Baixar nota</span></md-button>
                </md-table-cell>
                <md-table-cell v-if="item.status == 0" md-label="Status" data-title="Status">Processando nota</md-table-cell>
                <md-table-cell v-if="item.status == 1" md-label="Status" data-title="Status">Aguarando revisão</md-table-cell>
                <md-table-cell v-if="item.status == 2" md-label="Status" data-title="Status">Revisando</md-table-cell>
                <md-table-cell v-if="item.status == 3" md-label="Status" data-title="Status">Processado com sucesso</md-table-cell>
                <md-table-cell v-if="item.status == 1 || item.status == 2" md-label="Erro" data-title="Erro">Estamos verificando e já resolveremos =)</md-table-cell>
                <md-table-cell v-else md-label="ERRO" data-title="ERRO"> - </md-table-cell>
              </md-table-row>
            </md-table>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import Swal from 'sweetalert2';
export default {
    data: () => ({
        profile: null,
        isNotesLoaded: false,
        paginationIsLoading: false,
        notes: [],
        detailNotes: [],
        showDialog: false,
        params: {},
        page: 1,
        defaultPageSize: 100
    }),
    methods: {
      /* Helper function */
      downloadFile(fileURL, fileName) {
      // for non-IE
      if (!window.ActiveXObject) {
          var save = document.createElement('a');
          save.href = fileURL;
          save.target = '_blank';
          var filename = fileURL.substring(fileURL.lastIndexOf('/')+1);
          save.download = fileName || filename;
          if ( navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search("Chrome") < 0) {
            document.location = save.href; 
          // window event not working here
          }else{
            var evt = new MouseEvent('click', {
                'view': window,
                'bubbles': true,
                'cancelable': false
            });
            save.dispatchEvent(evt);
            (window.URL || window.webkitURL).revokeObjectURL(save.href);
          }   
      }
      // for IE < 11
      else if ( !! window.ActiveXObject && document.execCommand){
          var _window = window.open(fileURL, '_blank');
          _window.document.close();
          _window.document.execCommand('SaveAs', true, fileName || fileURL)
          _window.close();
        }
      },
      async openBrokerageNote(item){
        const fileobj = Object.assign([], item.brockerage_note_object)
        const file_name = fileobj.brockerage_note_file_name
        try {
          Swal.fire({
            title: 'Por favor aguarde',
            html: 'Estamos fazendo o download da nota ' + file_name,
            allowOutsideClick: false,
            onBeforeOpen: () => {
              Swal.showLoading()
            },
          })
          const url = "https://api.apurebolsa.com.br/" + item.brockerage_note_object.brockerage_note_file;
          this.downloadFile(url, file_name);
        } catch (e) {
          await this.$store.dispatch('alerts/error', 'Erro ao efetuar download.')
          console.error(e)
        } finally {
          setTimeout(
            Swal.close(), 2000
          )
        }
      },
      async getNotes(){
        try {
          if(this.profile == null){
            this.profile = await this.$store.dispatch("profile/me");
          }
          if(this.initialDate == null){
            var initialDate = new Date(this.profile.last_brokerage_note);
            initialDate = new Date(initialDate.getFullYear(), initialDate.getMonth(), 1);
            this.initialDate = moment(initialDate).format('DD/MM/YYYY');
            this.finalDate = moment(new Date(this.profile.last_brokerage_note)).format('DD/MM/YYYY');
          }
          this.params = {
            token: this.$store.getters.token,
            page: this.page,
            pageSize: this.defaultPageSize,
            initialDate: moment(this.initialDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
            finalDate: moment(this.finalDate, "DD/MM/YYYY").format("YYYY-MM-DD")
          }
          var notes = null;
          this.paginationIsLoading = true;
          notes = await this.$store.dispatch("profile/getNotes", this.params);
          notes = await notes.items
          console.log(notes);
          this.notes = this.notes.concat(notes.items);
          this.isNotesLoaded = true;
          this.paginationIsLoading = false;
        } catch (error) {
          console.log(error);
        }
      },
      pagnationNotes(){
        this.page++;
        this.getNotes();
      },
      filterNotes(){
        this.isNotesLoaded = false;
        this.page = 1;
        this.notes = [];
        this.paginationIsLoading = true;
        this.getNotes();
        this.paginationIsLoading = false;
      },
      closeDetail(){
        this.detailNotes = [];
        this.showDialog = false;
      }
    },
    beforeMount: function () {
      this.$nextTick(function () {
        this.filterNotes();
      });
    },
};
</script>
<style lang="scss" scoped>
  .md-table + .md-table {
    margin-top: 16px
  }
</style>